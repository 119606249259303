<template>
  <details class="transition-all duration-100 rounded-lg group">
    <summary>
      <div class="flex flex-row items-center">
        <slot />
        <span
          class="duration-100 transition-all arrow group-open:rotate-90 m-3"
        />
      </div>
    </summary>
    <slot name="details" />
  </details>
</template>

<style scoped>
/* Remove the default arrow because it's hard to style*/
details > summary {
  list-style: none;
}
summary::marker {
  display: none;
}
/* CSS arrow stolen from https://css-tricks.com/snippets/css/css-triangle/ */
span.arrow {
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;

  border-left: 6px solid black;
}
</style>
